export enum BanState {
  RUNNING = "Running",
  TERMINATED = "Terminated",
  EXPIRED = "Expired",
}

export interface BanService {
  id: number,
  name: string,
}

export interface MalleusPerson {
  uin: number,
  firstname: string,
  lastname: string,
}

export interface Ban {
  id: number,
  target_identity: string,  // UČO of banned person (or guest format)
  created_at: string,
  expiration: string,
  creator: number,  // UČO of the person who issued the ban
  state: BanState,
  reason_banned: string,
  terminator: number, // UČO of the person who cancelled the ban
  reason_unbanned: string,
  service: BanService,
}

export interface BanDetail {
  id: number,
  target: MalleusPerson,
  created_at: string,
  expiration: string,
  creator: MalleusPerson,
  state: BanState,
  reason_banned: string,
  terminator: MalleusPerson,
  reason_unbanned: string,
  service: BanService,
}

export interface BanCreateReq {
  Creator: number,  // UČO of the person who issued the ban
  TargetIdentity: string,
  Expiration: string,
  Reason: string,
  ServiceIds: number[],  // Array of service IDs that the target shall be banned from
  SendEmail: boolean,  // Send e-mail notification to the target
}

export interface BanTerminateReq {
  id: number, // ID of the ban that shall be terminated
  terminator: number, // UČO of the person who cancelled the ban
  reason: string,
}

export interface BanError {
  type?: string,
  title?: string,
  status?: number,
  detail?: string,
  instance?: string,
}
