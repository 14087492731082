/**
 * Contains all the API definitions of Malleus maleficarum
 */

export const PerunService = {
  "type": "rest",
  "commands": {
    "membersPage:get": {
      "httpMethod": "get",
      "endpoint": "/membersPage",
      "queryParameters": ["pageSize", "offset", "order", "sortColumn", "searchString"]
    },
    "memberSponsors:get": {
      "httpMethod": "get",
      "endpoint": "/members/{memberId}/sponsors"
    },
    "groupsMembers:get": {
      "httpMethod": "get",
      "endpoint": "/groups/members",
      "queryParameters": ["users"]
    },
    "sponsoredMembers:get": {
      "httpMethod": "get",
      "endpoint": "/sponsoredMembers"
    },
    "richUsers:get": {
      "httpMethod": "get",
      "endpoint": "/richUsers/{memberId}"
    },
    "sponsoredMembers:post": {
      "httpMethod": "post",
      "endpoint": "/sponsoredMembers",
      "body": `{
          "firstName": "{firstName}",
          "lastName": "{lastName}",
          "titleAfter": "{titleAfter}",
          "titleBefore": "{titleBefore}",
          "email": "{email}",
          "sponsor": "{sponsor}",
          "language": "{language}",
          "validityTo": "{validityTo}"
        }`
    },
    "groups:post": {
      "httpMethod": "post",
      "endpoint": "/groups",
      "body": `{
          "member": "{member}"
        }`
    },
    "groupMemberships:put": {
      "httpMethod": "put",
      "endpoint": "/groupMemberships",
      "body": `{
          "member": {member},
          "expiration": "{expiration}"
        }`
    },
    "groupMemberships-unlimited:put": {
      "httpMethod": "put",
      "endpoint": "/groupMemberships",
      "body": `{
          "member": {member},
          "expiration": null
        }`
    },
    "sponsor:post": {
      "httpMethod": "post",
      "endpoint": "/members/sponsor",
      "body": `{
          "members": {members},
          "sponsor": "{sponsor}",
          "validityTo": "{validityTo}"
        }`
    },
    "sponsor-unlimited:post": {
      "httpMethod": "post",
      "endpoint": "/members/sponsor",
      "body": `{
          "members": {members},
          "sponsor": "{sponsor}",
          "validityTo": null
        }`
    },
    "sponsorship:put": {
      "httpMethod": "put",
      "endpoint": "/sponsoredMembers/{member}/validity",
      "body": `{
          "sponsor": "{sponsor}",
          "validityTo": "{validityTo}"
        }`
    },
    "sponsorship-unlimited:put": {
      "httpMethod": "put",
      "endpoint": "/sponsoredMembers/{member}/validity",
      "body": `{
          "sponsor": "{sponsor}",
          "validityTo": null
        }`
    },
    "resetPassword:put": {
      "httpMethod": "put",
      "endpoint": "/members/{member}/password",
      "body": `{
          "language": "en"
        }`
    },
    "sponsorship:delete": {
      "httpMethod": "delete",
      "endpoint": "/members/{member}/sponsors",
      "body": `{
          "sponsor": {sponsor}
        }`
    },
  }
}
