<div class="topbar">
  <div class="mr-4">
    <smart-bookmarks></smart-bookmarks>
  </div>
  <div class="mx-auto">
    <!-- <smart-topbar-search></smart-topbar-search> -->
  </div>
  <div class="mb-0 mr-auto d-xl-block d-none">

  </div>
    <active-tasks-menu class="m-2"></active-tasks-menu>

    <smart-topbar-user-menu class="m-2"></smart-topbar-user-menu>
</div>
