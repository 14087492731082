// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: "SMART Dev",
  auth: {
    // Url of the Identity Provider
    issuer: 'https://id.muni.cz/oidc/',
    // URL of the SPA to redirect the user to after login
    redirectUri: 'https://smart-scifidev.dyn.cloud.e-infra.cz',
    // The SPA's id. The SPA is registerd with this id at the auth-server (clientId: 'server.code')
    clientId: '7e807abf-a542-4705-a0d4-410aef08c0c6',
    // Set the scope for the permissions the client should request
    // Important: Request offline_access to get a refresh token
    scope: 'openid profile email offline_access eduperson_entitlement eduperson_scoped_affiliation perun_api',
    responseType: 'code',
    showDebugInformation: false,
  },
  matomo: {
    disabled: true,
    trackerUrl: 'https://analytics.ics.muni.cz/',
    siteId: '657'
  },
  apiURL: "https://smart-scifidev.dyn.cloud.e-infra.cz/api/v1/",
 };
