<div>
  <div class='smart__sidebar' [ngClass]="isSidebarOpen ? 'smart__sidebar__toggled' : ''">
      <div class='smart__sidebar__inner'>
        <a href='javascript: void(0);' class='fe fe-x-circle smart__sidebar__close' (click)='toggle()'></a>
        <h5>
          <strong>{{'uiOptions.title' | translate}}</strong>
        </h5>
        <div class='smart__utils__line' style='margin-top: 25px; margin-bottom: 30px'></div>

        <div class='smart__sidebar__type mb-4'>
          <div class='smart__sidebar__type__title'>
            <span>{{'uiOptions.animation.title' | translate}}</span>
          </div>
          <div class='smart__sidebar__type__items'>
            <nz-select style='width: 100%;' [(ngModel)]='routerAnimation'
                       (ngModelChange)="setRouterAnimation($event)">
              <nz-option nzLabel="{{'uiOptions.animation.none' | translate}}" nzValue='none'></nz-option>
              <nz-option nzLabel="{{'uiOptions.animation.slideUp' | translate}}" nzValue='slideFadeinUp'></nz-option>
              <nz-option nzLabel="{{'uiOptions.animation.slideDown' | translate}}"
                         nzValue='slideFadeinRight'></nz-option>
              <nz-option nzLabel="{{'uiOptions.animation.fadeIn' | translate}}" nzValue='fadein'></nz-option>
              <nz-option nzLabel="{{'uiOptions.animation.zoom' | translate}}" nzValue='zoomFadein'></nz-option>
            </nz-select>
          </div>
        </div>
        <div class='smart__sidebar__type mb-2'>
          <div class='smart__sidebar__type__title'>
            <span>{{'uiOptions.leftMenuWidth' | translate}}</span>
          </div>
          <div class='smart__sidebar__type__items'>
            <nz-slider [ngModel]='leftMenuWidth' (ngModelChange)='setWidth($event)' [nzMin]='256' [nzMax]='330'>
            </nz-slider>
          </div>
        </div>
        <div class='smart__sidebar__type mb-2'>
          <div class='smart__sidebar__type__title'>
            <span>{{'uiOptions.otherOptions' | translate}}</span>
          </div>

          <div class='smart__sidebar__item'>
            <div class='smart__sidebar__label'>{{'uiOptions.leftMenuShadow' | translate}}</div>
            <div class='smart__sidebar__container'>
              <nz-switch [(ngModel)]='isMenuShadow' (ngModelChange)="settingChange($event, 'isMenuShadow')"
                         [nzDisabled]="menuLayoutType !== 'left'"></nz-switch>
            </div>
          </div>
          <div class='smart__sidebar__item'>
            <div class='smart__sidebar__label'>{{'uiOptions.contentFull' | translate}}</div>
            <div class='smart__sidebar__container'>
              <nz-switch [(ngModel)]='isContentMaxWidth' (ngModelChange)="setNarrowContent($event)">
              </nz-switch>
            </div>
          </div>
          <div class='smart__sidebar__item'>
            <div class='smart__sidebar__label'>{{'uiOptions.narrow' | translate}}</div>
            <div class='smart__sidebar__container'>
              <nz-switch [(ngModel)]='isAppMaxWidth'
                         (ngModelChange)="setNarrowView($event)"></nz-switch>
            </div>
          </div>
          <div class='smart__sidebar__item'>
            <div class='smart__sidebar__label'>{{'uiOptions.contrast' | translate}}</div>
            <div class='smart__sidebar__container'>
              <nz-switch [(ngModel)]='isGrayBackground' (ngModelChange)="settingChange($event, 'isGrayBackground')">
              </nz-switch>
            </div>
          </div>
          <div class='smart__sidebar__item'>
            <div class='smart__sidebar__label'>{{'uiOptions.squares' | translate}}</div>
            <div class='smart__sidebar__container'>
              <nz-switch [(ngModel)]='isSquaredBorders' (ngModelChange)="settingChange($event, 'isSquaredBorders')">
              </nz-switch>
            </div>
          </div>
          <div class='smart__sidebar__item'>
            <div class='smart__sidebar__label'>{{'uiOptions.shadows' | translate}}</div>
            <div class='smart__sidebar__container'>
              <nz-switch [(ngModel)]='isCardShadow' (ngModelChange)="settingChange($event, 'isCardShadow')"></nz-switch>
            </div>
          </div>
        </div>
        <div class='smart__sidebar__type mb-2'>
          <div class='smart__sidebar__type__title'>
            <span>{{'uiOptions.colorOptions' | translate}}</span>
          </div>
          <div class='smart__sidebar__item'>
            <div class='smart__sidebar__label'>{{'uiOptions.colorOptions.accent' | translate}}</div>
            <div class='smart__sidebar__container'>
              <input type='color' id='colorPicker' (change)='setPrimaryColor($event)' [ngModel]='primaryColor'
                     [ngClass]='{reset: primaryColor === defaultColor}' />
            </div>
            <button nz-button nzSize="small" class="ml-3" (click)='resetColor()'><i nz-icon nzType="close"></i>{{'uiOptions.colorOptions.accent.reset' | translate}}</button>
          </div>
        </div>
      </div>
  </div>
  <a href='javascript: void(0);' style='bottom: calc(20% + 120px)' (click)='toggle()'
     nzTooltipTitle="{{'uiOptions.tooltip' | translate}}"
     nzTooltipPlacement='left' nz-tooltip class='smart__sidebar__toggleButton'>
    <i class='fe fe-settings'></i>
  </a>
  <a href='javascript: void(0);' style='bottom: calc(20% + 60px)'
     (click)="setTheme(theme === 'default' ? 'dark' : 'default')"
     nzTooltipTitle="{{'uiOptions.dark.tooltip' | translate}}"
     nzTooltipPlacement='left' nz-tooltip class='smart__sidebar__toggleButton'
  >
    <i *ngIf="theme === 'default'" class='fe fe-moon'></i>
    <i *ngIf="theme !== 'default'" class='fe fe-sun'></i>
  </a>
  <a href='https://link-to-manual' target='_blank' rel='noopener noreferrer' style='bottom: calc(20%)'
     nzTooltipTitle='Documentation' nzTooltipPlacement='left' nz-tooltip class='smart__sidebar__toggleButton'>
    <i class='fe fe-book-open'></i>
  </a>
</div>

<ng-template #colorTpl let-setting let-colors='colors' let-active='active'>
  <ng-container *ngFor='let item of colors'>
    <a href='javascript: void(0);' (click)='settingChange(item, setting)' class='smart__sidebar__select__item' [ngClass]="{
        smart__sidebar__select__item__active: active === item, smart__sidebar__select__item__black: item === 'dark',
        smart__sidebar__select__item__white: item === 'white', smart__sidebar__select__item__gray: item === 'gray',
        smart__sidebar__select__item__blue: item === 'blue', smart__sidebar__select__item__img: item === 'image'}"></a>
  </ng-container>
</ng-template>

