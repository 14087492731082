import { ActionReducerMap, createFeatureSelector, createSelector } from "@ngrx/store";
import * as RouterStore from '@ngrx/router-store'
import * as BookmarksReducer from './bookmarks/bookmarks.reducer'
import * as SettingsStore from './settings/reducers'
import * as TasksReducer from './tasks/tasks.reducer'
import * as UserReducer from './user/user.reducer'
import * as NotificationsReducer from './notifications/notifications.reducer'

export const reducers: ActionReducerMap<any> = {
  router: RouterStore.routerReducer,
  bookmarks: BookmarksReducer.reducer,
  settings: SettingsStore.reducer,
  tasks: TasksReducer.reducer,
  user: UserReducer.reducer,
  notifications: NotificationsReducer.reducer,
}

export const getSettingsState = createFeatureSelector<any>('settings')
export const getSettings = createSelector(getSettingsState, SettingsStore.getSettings)


