/**
 * Contains all the API interfaces of SMART tasks
 */


/**
 * Flavors of the target API
 *
 * Defines type of API that shall be used by SMART Backend for communication with target service (endpoint)
 */
export enum ETaskFlavor {
  REST = 'REST',
  SOAP = 'SOAP',
  SHELL = 'SHELL',
  DB = 'DATABASE',
  MQ = 'MQ',
  PSWS = 'PSWS',
}


/**
 * Priority of the task.
 */
export enum ETaskPriority {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL',
  REALTIME = 'REALTIME',
}


/**
 * Status of the task execution.
 */
export enum ETaskStatus {
  REQUESTED = 'REQUESTED',  // Backend confirmed receiving the Task
  PROCESSING = 'PROCESSING',  // The Task passed all the validations TODO: Why is a separate status and not go to QUEUED directly?
  QUEUED = 'QUEUED',  // The Task is in the execution queue
  STARTING = 'STARTING',  // TaskExecutor removed the Task from queue  TODO: Why is a separate status and not go to RUNNING directly?
  RUNNING = 'RUNNING',  // The Task is currently running
  COMPLETED = 'COMPLETED',  // The Task successfully completed
  FAILED = 'FAILED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',  // TODO: Why is a separate status and not go to TERMINATING directly?
  TERMINATING = 'TERMINATING',  // Received request for Task cancellation
  CANCELLED = 'CANCELLED',  // The Task was cancelled by a user
  NOTFOUND = 'NOTFOUND',
  UNKNOWN = 'UNKNOWN',  // Unknown Task status
}

/**
 * Predefined credential UUIDs
 */
export enum ETaskCredentials {
  TOKEN_PASSTHROUGH = '00000000-0000-0000-0000-000000000000'
}

/**
 * Task interface, returned by GET /task-dispatcher/task
 */
export interface ITaskResponse {
  'uuid': string, // Task UUID
  'name': string,  // Human-readable task name (user-supplied)
  'flavor': ETaskFlavor,
  'priority': ETaskPriority,
  'ownerUser': string, // UUID of the Task owner
  'ownerGroup': string, // UUID of the Task group
  'timeSent': string, // Time when the Task was created (received by the backend)
  'timeProcessed': string, // Time of the last status change (completion of the Task if finished)
  'turns': number, // Number of runs of the task (ideally 1, in case of failure and retry higher number)
  'payload': ITaskPayload,
  'status': ITaskStatus,
  'result': {[index: string]: string},
}


/**
 * Task State Overview, returned by GET /task-dispatcher/taskIdsList
 */
export interface ITaskStateOverview {
  'uuid': string,  // UUID of the task
  'name': string,  // Human-readable task name (user-supplied)
  'status': ITaskStatus
}


/**
 * Task Request interface for submitting tasks to the backend (POST /task-dispatcher/task).
 */
export interface ITaskRequest {
  'name': string,  // User-supplied name of the task
  'flavor': ETaskFlavor,
  'priority': ETaskPriority,
  'payload': ITaskPayload,
}


/**
 * Task Error interface, which is returned when something goes sour.
 */
export interface ITaskError {
  'message': string,  // Human-readable error description (explains the HTTP Error Status Code)
}


/**
 * Task Payload interface holds the critical data of the task.
 *
 * Specifies endpoint (target), command and its parameters.
 */
export interface ITaskPayload {
  'endpoint': string, // UUID of the Task endpoint (target specification)
  'credential': string, // UUID of the credential object which shall be used for authorization at endpoint
  'command': string, // Command which shall be run on the endpoint (task specification, differs depending on the flavor)
  'arguments': string[], // Parameters of the command (differs depending on the flavor)
}


/**
 * Task Status describes the current state of the execution.
 */
export interface ITaskStatus {
  'status': ETaskStatus,
  'message': string, // Contains the result in case of completed Task, otherwise can contain detailed info about current state
}
