/**
 * Barrel export for the neater API interfaces imports
 */

export * from "./assets.interfaces";
export * from "./generic.interfaces";
export * from "./tasks.interfaces";

// Admin tools
export * from "./malleus.interfaces";
export * from "./m365.interfaces";
export * from "./occupationService.interfaces";
export * from "./perun.interfaces";
export * from "./psws.interfaces";

// TODO: New backed

import { PersonService } from "./person.service";
import { ReservationService } from "./reservation.service";
import { ZappaService } from "./zappa.service";

// TODO: create a generic interface instead of any
const api: { [key: string]: any } = {
  "person": PersonService,
  "reservation": ReservationService,
  "zappa": ZappaService
}

export default api;
