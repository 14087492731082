/**
 * Contains all the generic API interfaces of SMART
 */

/* Health Check */

export enum EHealthStatus {
  UNKNOWN = 'Unknown',
  HEALTHY = 'Healthy',
  UNHEALTHY = 'Unhealthy',
}

export interface IHealthStatus {
  status: EHealthStatus,
  totalDuration: string,  // HH:mm:ss.fffffff containing the processing time
  entries: IHealthStatusEntries
}

export interface IHealthStatusEntries {
  [index: string]: IHealthStatusEntry;
}

export interface IHealthStatusEntry {
  status: EHealthStatus,
  duration: string,  // HH:mm:ss.fffffff containing the processing time
  data: any,
  tags: string[],
  description?: string,
  exception?: string,
}

export const HealthStatusUnavailable: IHealthStatus = {
  status: EHealthStatus.UNKNOWN,
  totalDuration: "00:00:00.0000000",
  entries: {}
}

/* Errors */

export interface IErrorOld { // SMART API Problem object
  instance: string,  // FIXME: Some URI???
  type: string,  // URI of the error type
  parameters: object,  // FIXME: WTF???
  title: string,
  detail: string,  // FIXME: ???
  status: {
    statusCode: number,  // Status code (corresponding with HTTP status codes?)
    reasonPhrase: string,  // FIXME: Human readable error message?
  }
  path: string,  // Path (URI of the SMART API endpoint) which caused the error
  message: string  // Identifier of the human-readable description
}

export function isSMARTError(obj: any): obj is IErrorOld {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj['type'] === 'string' &&
    typeof obj['title'] === 'string' &&
    typeof obj['status'] === 'number' &&
    typeof obj['path'] === 'string' &&
    typeof obj['message'] === 'string'
  );
}

/* Section: TODO: New backend */

export interface ICommandParameters {
  [name: string]: any
}

export interface IResponse {
  statusCode: number,  // (HTTP) Status code of the invocation of the service.
  errorMessage: string | null,  // Human-readable explanation of the error.
  response: any,  // Contains the response object for the command.
}

/**
 * SMART API Error
 *
 * Error object raised when calling SMART API. This indicates a problem originating in SMART system.
 */
export interface IError {
  status: number,  // HTTP Status code when calling SMART API
  type: string,  // URL defining the error (rfc9110 reference)
  title: string,  // Human-readable error title
  instance?: string,  // Endpoint which was called
  traceId?: string,  // Trace ID for debugging
  detail?: string,  // Human-readable error description
  errors?: {  // List of detailed errors that occurred
    name: string,
    reason: string
  }[]
}
export function isError(obj: any): obj is IError {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj['status'] === 'number' &&
    obj['status'] !== 200
  );
}
