import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from "./user.state";

export const userFeatureName = "user";

export const selectUserState = createFeatureSelector<UserState>(userFeatureName);

export const selectUserDetails = createSelector(
  selectUserState,
  (state: UserState) => state
);

export const selectIsAuthenticated = createSelector(
  selectUserState,
  (state: UserState) => !!state.id
);
