/**
 * Contains all the API objects of Microsoft 365 Security Groups
 */

export type IMuniADObject = IMuniADUser | IMuniADGroup;

export interface IMuniADUser {
  DistinguishedName: string,  // DN - Internal AD identifier
  SamAccountName: string, // UČO
  displayName: string,  // Full name
  mail: string,  // Primary mail
  // AD internal items
  "msDS-cloudExtensionAttribute2"?: boolean, // active/synchronized user
}

export function isMuniADUser(object: IMuniADObject): object is IMuniADUser {
  return object.hasOwnProperty('SamAccountName');
}

export interface IMuniADGroup {
  objectGUID: string,  // Object GUID - Group identifier
  DistinguishedName: string,  // DN - Internal AD identifier
  displayName: string,  // Group name
  mail: string,  // Primary mail

  authOrig?: IMuniADUser[], // List of users eligible to contribute to the group
  dlMemSubmitPerms?: IMuniADGroup[], // List of groups eligible to contribute to the group
  // If both authOrig and dlMemSubmitPerms are empty, everyone (even non-M365 users) can contribute (default)
  // If the authOrig is empty and dlMemSubmitPerms contains DN of empty system group, the group is inaccessible
  msExchEnableModeration?: boolean,  // Moderated group
  msExchModeratedByLink?: IMuniADUser[],  // List of moderators (max 10)
  msExchBypassModerationLink?: IMuniADUser[],  // List of unmoderated users
  msExchBypassModerationFromDLMembersLink?: IMuniADGroup[], // List of unmoderated groups

  // AD internal items
  "extensionAttribute1"?: boolean, // active/synchronized group
}

export function isMuniADGroup(object: IMuniADObject): object is IMuniADGroup {
  return object.hasOwnProperty('objectGUID');
}

export interface IMuniADGroupMailModeration {
  DistinguishedName: string,  // DN - Internal AD identifier
  authOrig: string[],  // List of user DNs eligible to contribute to the group
  dlMemSubmitPerms: string[], // List of group DNs eligible to contribute to the group
  msExchEnableModeration: boolean,  // Moderated group
  msExchModeratedByLink: string[],  // List of moderator DNs (max 10)
  msExchBypassModerationLink: string[],  // List of unmoderated user DNs
  msExchBypassModerationFromDLMembersLink: string[], // List of unmoderated group DNs
}


export interface IMuniSPOSite {
  ConditionalAccessPolicy:  number,
  DefaultLinkPermission:  number,
  DefaultLinkToExistingAccess:  boolean,
  DefaultSharingLinkType:  number,
  Description:  string | null,
  DisableSharingForNonOwnersStatus:  null,
  SharingCapability: string | null,
  StorageQuota:  number,
  StorageQuotaWarningLevel:  number,
  StorageUsageCurrent:  number,
  Template:  string,
  Title:  string,
  Url:  string,
  WebCount:  number | null,
}

export interface IMuniSPOSiteModificationReq {
  url: string,
  sharingcapability: string,
  StorageQuota: number,
}
