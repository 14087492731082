import { createAction, props } from "@ngrx/store";

export enum NotificationActionNames {
  NEW = '[Notification] New notification',
  NOTIFICATIONS_SEEN = '[Notification] Notifications seen'
}

export const NotificationNew = createAction(NotificationActionNames.NEW, props<{
  uuid: string,
  name: string,
  lastUpdate: string,
  status: string
}>());

export const NotificationsSeen = createAction(NotificationActionNames.NOTIFICATIONS_SEEN);
