export const SETTINGS_FEATURE_KEY = 'settings';
export interface SystemSettings {
  authProvider: string, // internal, sso
  logo?: string | null,
  locale: string,
  isSidebarOpen: boolean,
  isSupportChatOpen: boolean,
  isMobileView: boolean,
  isMobileMenuOpen: boolean,
  isMenuCollapsed: boolean,
  menuLayoutType: string, // left, top, nomenu
  routerAnimation: string, // none, slideFadeinUp, slideFadeinRight, Fadein, zoomFadein
  menuColor: string, // white, dark, gray
  theme: string, // default, dark
  authPagesColor: string, // white, gray, image
  primaryColor: string,
  leftMenuWidth: number,
  isMenuUnfixed: boolean,
  isMenuShadow: boolean,
  isTopbarFixed: boolean,
  isGrayTopbar: boolean,
  isContentMaxWidth: boolean,
  isAppMaxWidth: boolean,
  isGrayBackground: boolean,
  isCardShadow: boolean,
  isSquaredBorders: boolean,
  isBorderless: boolean,
}

export interface SettingsState {
  settings: SystemSettings,
  loaded: boolean;
  error?: string | null
}

export const initialSettings: SettingsState = {
  settings: {
    authProvider: 'internal',
    logo: 'SMART',
    locale: 'cs-CZ',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left',
    routerAnimation: 'slideFadeinUp',
    menuColor: 'white',
    theme: 'default',
    authPagesColor: 'image',
    primaryColor: '#0000DC',
    leftMenuWidth: 256,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isGrayTopbar: false,
    isContentMaxWidth: true,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
  },
  loaded: false,
  error: null
}
