/**
 * Barrel export for the neater API interfaces imports
 */

export * from "./assets.interfaces";
export * from "./generic.interfaces";
export * from "./tasks.interfaces";

// Admin tools
export * from "./m365.interfaces";
export * from "./perun.interfaces";
export * from "./psws.interfaces";

// TODO: New backed

import { BanningService } from "./banning.service";
import { OccupationService } from "./occupation.service";
import { PersonService } from "./person.service";
import { ReservationService } from "./reservation.service";
import { ZappaService } from "./zappa.service";
import { PerunService } from "./perun.service";
import { PSWSService } from "./psws.service";

// TODO: create a generic interface instead of any
const api: { [key: string]: any } = {
  "banning": BanningService,
  "occupation": OccupationService,
  "person": PersonService,
  "reservation": ReservationService,
  "zappa": ZappaService,
  "perun": PerunService,
  "psws": PSWSService
}

export default api;
