import { Component, OnInit } from '@angular/core';
import UserList from './acl-list.model';

@Component({
  selector: 'smart-user-acl-listing',
  templateUrl: './user-acl-listing.component.html',
  styleUrls: ['./user-acl-listing.component.scss']
})
export class UserAclListingComponent implements OnInit {
  date = null;
  listOfUsers: UserList[] = [];


  constructor() { }

  ngOnInit(): void {
  }

  onChange(result: Date): void {

  }

}
