export interface ITaskNotification {
  uuid: string,
  name: string, // Human-readable task name (user-supplied)
  lastUpdate: string,
  status: string,
}

export interface NotificationsState {
  [index: string]: ITaskNotification
}

export const initialNotificationsState: NotificationsState = {};
