import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-breadcrumbs',
  templateUrl: './layout-breadcrumbs.component.html',
  styleUrls: ['./layout-breadcrumbs.component.scss']
})
export class LayoutBreadcrumbsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
