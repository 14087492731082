<nz-collapse>
  <nz-collapse-panel [nzHeader]="taskDetailHeader" nzActive="true" nzShowArrow="false" nzDisabled="true" style="cursor: default">

    <ng-container *ngIf="taskUUID !== '' && (onlineTask !== undefined || localTask !== undefined); else taskNotFound">
      <ng-container *ngIf="displayLocalTask; else displayOnlineTask">
        <ng-container *ngIf="localTask !== undefined; else taskNotFound">
          <ng-container *ngTemplateOutlet="taskDetail; context: { $implicit: localTask }"></ng-container>
        </ng-container>
      </ng-container>
      <ng-template #displayOnlineTask>
        <ng-container *ngIf="onlineTask !== undefined; else taskNotFound">
          <ng-container *ngTemplateOutlet="taskDetail; context: { $implicit: onlineTask }"></ng-container>
        </ng-container>
      </ng-template>
    </ng-container>

  </nz-collapse-panel>
</nz-collapse>

<!-- TEMPLATES -->
<ng-template #taskDetailHeader>
  <div class="task-detail-header">
    <div>{{taskName()}}</div>
    <div *ngIf="taskName() !== ''" style="cursor: pointer" (click)="toggleLocalOnline()">
      <nz-badge *ngIf="displayLocalTask === false; else localTaskStatus" nzStatus="success" nzText="{{'user.tasks.source.online' | translate}}"></nz-badge>
      <ng-template #localTaskStatus>
        <nz-badge nzStatus="default" nzText="{{'user.tasks.source.local' | translate}}"></nz-badge>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #taskDetail let-task>
      <div class="description">
        <div class="desc-label">{{'user.tasks.status.title' | translate | firstLetterCapital}}</div>
        <div class="desc-item">{{('user.tasks.status.' + task.status) | translate}}</div>

        <div class="desc-label">{{'user.tasks.created' | translate | firstLetterCapital}}</div>
        <div class="desc-item">{{task.timeCreated | localisedDate: 'medium'}}</div>

        <div class="desc-label">{{'user.tasks.lastUpdate' | translate | firstLetterCapital}}</div>
        <div class="desc-item">{{task.timeStatusChanged | localisedDate: 'medium'}}</div>

        <div class="desc-label">{{'user.tasks.priority.title' | translate | firstLetterCapital}}</div>
        <div class="desc-item">{{('user.tasks.priority.' + task.priority) | translate}}</div>

        <div class="desc-label">{{'user.tasks.endpoint' | translate | firstLetterCapital}}</div>
        <div class="desc-item">
          <nz-collapse nzBordered="false">
            <nz-collapse-panel nzHeader="{{task.payload.endpoint}}">
              <div class="description">
                <div class="desc-label">{{'user.tasks.credential' | translate | firstLetterCapital}}</div>
                <div class="desc-item">{{task.payload.credential}}</div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>

        <div class="desc-label">{{'user.tasks.command.title' | translate | firstLetterCapital}}</div>
        <div class="desc-item">
          <nz-collapse nzBordered="false">
            <nz-collapse-panel nzHeader="{{task.payload.command}}">
              <div class="description">
                <div class="desc-label">{{'user.tasks.command.parameters' | translate | firstLetterCapital}}</div>
                <div class="desc-item">{{task.payload.arguments.join(', ')}}</div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>

        <div class="desc-label">{{'user.tasks.owner' | translate | firstLetterCapital}}</div>
        <div class="desc-item">{{task.owner}}</div>

        <div class="desc-label">{{'user.tasks.group' | translate | firstLetterCapital}}</div>
        <div class="desc-item">{{task.group}}</div>

        <div></div>
        <div class="task-actions">
          <button type="button" class="btn btn-outline-danger mx-1" (click)="cancelTask(task)">
            <i class="fe fe-x-circle"></i>
            {{'user.tasks.cancel' | translate | firstLetterCapital}}
          </button>
          <button type="button" class="btn btn-outline-warning mx-1" (click)="retryTask(task)">
            <i class="fe fe-repeat"></i>
            {{'user.tasks.retry' | translate | firstLetterCapital}}
          </button>
        </div>
      </div>
</ng-template>

<ng-template #taskNotFound>
  <nz-empty></nz-empty>
</ng-template>
<!-- End of TEMPLATES -->
