import { Action, createReducer, on } from "@ngrx/store";
import { initialNotificationsState, NotificationsState } from "./notifications.state";
import { NotificationNew, NotificationsSeen } from "./notifications.actions";
import { TaskFinalStates } from "../../util/task";

const notificationsReducer = createReducer(
  initialNotificationsState,

  on(NotificationNew, (state, notification) => {
    return {
      ...state,
      [notification.uuid]: {
        uuid: notification.uuid,
        name: notification.name,
        lastUpdate: notification.lastUpdate,
        status: notification.status
      }
    }
  }),

  on(NotificationsSeen, (state) => {
    let newState: {[index: string]: any} = {};

    for (let uuid in state) {
      if (!TaskFinalStates.has(state[uuid].status)) {
        newState[uuid] = state[uuid];
      }
    }

    return newState;
  }),
);

export function reducer(state: NotificationsState | undefined, action: Action) {
  return notificationsReducer(state, action);
}
