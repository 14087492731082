import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { selectTasksState, TaskCancelled } from "../../../store/tasks";
import { map, Observer } from "rxjs";
import { ETaskLocalState, ITask } from "../../../util/task";
import { SmartApiService } from "../../../../shared/services/smart-api.service";
import { HttpErrorResponse } from "@angular/common/http";
import { isSMARTError } from "../../../../shared/api";
import { TranslateService } from "@ngx-translate/core";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: 'user-tasks-list',
  templateUrl: './user-tasks-list.component.html',
  styleUrls: ['./user-tasks-list.component.scss']
})
export class UserTasksListComponent implements OnInit {

  readonly localTasks$ = this.store.select(selectTasksState).pipe(
    map(tasks => ({
      notifications: tasks.notifications,
      tasks: Object.values(tasks.tasks)
        .filter(task => task.status === ETaskLocalState.CREATED || task.status === ETaskLocalState.SERV_UNAVAILABLE)
        .sort((a, b) => {
          const aMs = Date.parse(a.timeStatusChanged);
          const bMs = Date.parse(b.timeStatusChanged);
          return bMs - aMs; // Descending order
        })
    }))
  );

  public onlineTasksLoading: boolean = true;
  public onlineTasks: ITask[] | undefined;

  constructor(private store: Store,
              private api: SmartApiService,
              private translate: TranslateService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    let tasksObs: Observer<ITask[]> = {
      next: tasks => this.onlineTasks = tasks,
      error: err => {
        this.errorHandler(err);
        this.onlineTasksLoading = false;
      },
      complete: () => this.onlineTasksLoading = false
    }
    this.api.getTasks().subscribe(tasksObs);
  }

  cancelTask(task: ITask): void {
    this.store.dispatch(TaskCancelled({uuid: task.uuid}));
  }

  retryTask(task: ITask): void { }


  // Privates
  private errorHandler(errorMsg: HttpErrorResponse) {
    let message: string;
    if (isSMARTError(errorMsg.error)) {
      message = errorMsg.error.message;
    } else {
      message = 'error.' + errorMsg.status.toString() + '.title';
    }
    this.notification.error(
      this.translate.instant('error.title'),
      this.translate.instant(message)
    );
  }
}
