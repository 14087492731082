import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectTasksState } from "../../../store/tasks";
import { map, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'user-tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss']
})
export class UserTasksComponent implements OnInit, OnDestroy {

  options = [
    { label: 'active', value: 'active', useTemplate: true },
    { label: 'all', value: 'all', useTemplate: true },
  ];

  readonly activeTasksExist$ =  this.store.select(selectTasksState).pipe(
    map( state => Object.keys(state.tasks).length > 0 )
  );

  selectedTab = 0;
  showTaskDetails: string | undefined;

  private subscriptions: Subscription[];

  constructor(private store: Store, private route: ActivatedRoute) {
    this.subscriptions = [
      this.route.queryParams.subscribe( params => {
        this.showTaskDetails = params['task'];
        if (params['task'] !== undefined) {
          this.selectedTab = 0;
        }
      }),
      this.activeTasksExist$.subscribe( activeTasksExist => {
        if (this.showTaskDetails) {
          if (this.selectedTab == 1 && !activeTasksExist) {
            this.selectedTab = 0;
          }
        } else {
          if (!activeTasksExist && this.selectedTab == 0) {
            this.selectedTab++;
          }
        }
      }),
    ];
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( subscription => subscription.unsubscribe());
  }
}
