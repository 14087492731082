/**
 * Contains all the API objects of Perun
 */

export namespace psws {

  export const UUID = "fc652cc2-cf58-4837-98a1-41b1802553a3";

  export enum EFSLogixProfileStatus {
    OPENED = "Opened",
    CLOSED = "Closed",
    CORRUPTED = "Corrupted"
  }

  export interface IFSLogixProfile {
    "Identity": string,
    "ProfileStatus": EFSLogixProfileStatus,
    "ProfileStatusDetail": string,
    "ConnectedOn": string
  }

  export interface IFSLogixError {
    "ProcessedObjectID": string,
    "ExceptionType": string,
    "ExceptionMessage": string,
    "InvocationInfo": any
  }

  export type IFSLogixResponse = IFSLogixProfile | IFSLogixError;

  export function isFSLogixError(object: IFSLogixResponse): object is IFSLogixError {
    return object.hasOwnProperty('ExceptionType');
  }

  export function isFSLogixProfile(object: IFSLogixResponse): object is IFSLogixProfile {
    return object.hasOwnProperty('Identity');
  }
}
