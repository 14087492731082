<div class="d-flex flex-column">
  <div class="text-center">
    <h1 class="mt-4">{{'login.welcome' | translate:{'appName': appName} }}</h1>
    <p>{{ 'login.description' | translate }}</p>
  </div>

  <div class="d-flex flex-auto align-items-center">
    <nz-card nzTitle="{{ 'login.cardTitle' | translate }}" class="text-center login-options-card">
      <button class="btn-logo" (click)="login()">
        <img *ngIf="isCzech()" src="../../../../assets/images/dark_cs-min.png" alt="MUNI Single Sign-On" class="w-100">
        <img *ngIf="!isCzech()" src="../../../../assets/images/dark_en-min.png" alt="MUNI Single Sign-On" class="w-100">
      </button>
    </nz-card>
  </div>
</div>

<!-- <language-switch></language-switch> -->
