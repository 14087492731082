import { Action, createReducer, on } from "@ngrx/store";
import { initialUserState, UserState } from "./user.state";
import { UserProfileLoaded, UserProfileLoadFailed } from './user.actions'


const userReducer = createReducer(
  initialUserState,
  on(UserProfileLoaded, (state, claims) => ({
    ...state,
    id: claims.preferred_username,
    login: claims.sub,
    full_name: claims.name,
    given_name: claims.given_name,
    family_name: claims.family_name,
    email: claims.email,
  })),
  on(UserProfileLoadFailed, () => initialUserState)
)

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
