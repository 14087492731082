import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { map } from "rxjs";
import { selectTasksState, TaskCancelled } from "../../../store/tasks";
import { ITask } from "../../../util/task";

@Component({
  selector: 'active-tasks-list',
  templateUrl: './active-tasks-list.component.html',
  styleUrls: ['./active-tasks-list.component.scss']
})
export class ActiveTasksListComponent implements OnInit {

  readonly tasks$ =  this.store.select(selectTasksState).pipe(
    map(tasks => ({
      notifications: tasks.notifications,
      tasks: Object.values(tasks.tasks).sort((a, b) => {
        const aMs = Date.parse(a.timeStatusChanged);
        const bMs = Date.parse(b.timeStatusChanged);
        return bMs - aMs; // Descending order
      })
    }))
  );

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  cancelTask(task: ITask): void {
    this.store.dispatch(TaskCancelled({uuid: task.uuid}));
  }

  retryTask(task: ITask): void { }
}
