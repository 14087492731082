<nz-layout>
  <nz-sider nzWidth="200px" class="left-sider" nzCollapsible [(nzCollapsed)]="leftSiderCollapsed">

    <a routerLink="/" class="logo-container">
      <img class="logo-muni-ics" src="../../../../../assets/images/logo_MUNI-ICS.png" alt="Logo MUNI ICS"/>
      <img class="logo-smart" src="../../../../../assets/images/logo_SMART.png" alt="Logo SMART"/>
    </a>

    <smart-main-menu [collapsed]="leftSiderCollapsed"></smart-main-menu>
  </nz-sider>

  <nz-layout>
    <nz-header>
      <smart-topbar></smart-topbar>
    </nz-header>

    <nz-content class="d-flex flex-column">
      <router-outlet></router-outlet>
    </nz-content>

    <nz-footer>
      <smart-footer></smart-footer>
    </nz-footer>
  </nz-layout>

</nz-layout>
