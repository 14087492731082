import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-error-500',
  templateUrl: './500.component.html',
})
export class Error500Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public static overwriteAppWithErrorPage(
    message:string = "Server malfunction prevented the application to be loaded. Please, try again later."
  ) {

    let rootEl = document.getElementsByTagName('app-root')[0];
    rootEl.classList.add('error');

    let layout = document.createElement('nz-layout');
    layout.classList.add('ant-layout', 'layout-modal');

    let content = layout.appendChild( document.createElement('nz-content') );
    content.classList.add('ant-layout-content');

    let component = content.appendChild( document.createElement('smart-error-500') );

    let errorPage = component.appendChild( document.createElement('section') );
    errorPage.id = "error";

    let title = errorPage.appendChild( document.createElement('h1') );
    title.appendChild( document.createTextNode('Internal Server Error') );

    let subtitle = errorPage.appendChild( document.createElement('h2') );
    subtitle.appendChild( document.createTextNode('— 500 —') );

    let text = errorPage.appendChild( document.createElement('p') );
    text.appendChild(document.createTextNode(message));

    let refreshBtn = errorPage.appendChild( document.createElement('a') );
    refreshBtn.classList.add('btn', 'btn-outline-primary');
    refreshBtn.setAttribute('href', window.location.href);
    refreshBtn.addEventListener('click', ()=>{ window.location.reload() } );
    refreshBtn.appendChild( document.createTextNode('Refresh') );

    rootEl.replaceChildren(layout);
  }

}
