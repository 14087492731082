import {Action, createAction, props} from '@ngrx/store'
import {SystemSettings} from "./model";

export enum SettingsActionNames {
  INIT = '[Settings] Init',
  LOAD_SETTINGS = '[Settings] Load Settings',
  CHANGE_SETTINGS = '[Settings] Change Settings',
  LOAD_SETTINGS_SUCCESS = '[Settings] Load Setting Success',
  LOAD_SETTINGS_FAILURE = '[Settings] Load Setting Failure',
  CHANGE_SETTINGS_SUCCESS = '[Settings] Change Setting Success',
  CHANGE_SETTINGS_FAILURE = '[Settings] Change Setting Failure',
  SWITCH_LOCALE = '[Settings] Switch UI Localization',
  SET_SIDEBAR_STATE = '[Settings] Set Sidebar State',
  SET_THEME_COLOR = '[Settings] Set Theme Color',
  SET_THEME = '[Settings] Set Theme',
  SET_MENU_COLLAPSED = '[Settings] Set Menu Collapsed',
  SET_ROUTER_ANIMATION = '[Settings] Set Router Animation',
  SET_MENU_COLOR = '[Settings] Set Menu Color',
  SET_NARROW_VIEW = '[Settings] Set Narrow View',
  SET_NARROW_CONTENT = '[Settings] Set Narrow Content',
}

export const Init = createAction(SettingsActionNames.INIT);
export const LoadSettings = createAction(SettingsActionNames.LOAD_SETTINGS);
export const LoadSettingsSuccess = createAction(SettingsActionNames.LOAD_SETTINGS_SUCCESS, props<SystemSettings>());
export const LoadSettingsFailure = createAction(SettingsActionNames.LOAD_SETTINGS_FAILURE, props<{ error: string | null }>());
export const ChangeSettings = createAction(SettingsActionNames.CHANGE_SETTINGS, props<any>());
export const ChangeSettingsSuccess = createAction(SettingsActionNames.CHANGE_SETTINGS_SUCCESS, props<SystemSettings>());
export const ChangeSettingsFailure = createAction(SettingsActionNames.CHANGE_SETTINGS_FAILURE, props<{ error: string | null }>());
export const SwitchLocale = createAction(SettingsActionNames.SWITCH_LOCALE, props<{ locale: string }>());
export const SetSidebarState = createAction(SettingsActionNames.SET_SIDEBAR_STATE, props<{ isSidebarOpen: boolean }>());
export const SetThemeColor = createAction(SettingsActionNames.SET_THEME_COLOR, props<{ primaryColor: string }>());
export const SetTheme = createAction(SettingsActionNames.SET_THEME, props<{ theme: string }>());
export const SetMenuCollapsed = createAction(SettingsActionNames.SET_MENU_COLLAPSED, props<{ isMenuCollapsed: boolean }>());
export const SetRouterAnimation = createAction(SettingsActionNames.SET_ROUTER_ANIMATION, props<{ routerAnimation: string }>());
export const SetMenuColor = createAction(SettingsActionNames.SET_MENU_COLOR, props<{ menuColor: string }>());
export const SetNarrowView = createAction(SettingsActionNames.SET_NARROW_VIEW, props<{ isAppMaxWidth: boolean }>());
export const SetNarrowContent = createAction(SettingsActionNames.SET_NARROW_CONTENT, props<{ isContentMaxWidth: boolean }>());


/*

export const SetStateAction = createAction(SET_STATE, props<{payload: { [p: string]: any }}>());
export const ChangeSettingAction = createAction(CHANGE_SETTING, props<{payload: { [p: string]: any }}>());
*/
