import { ETaskFlavor, ETaskPriority, ETaskStatus, ITaskPayload, ITaskResponse } from "../../shared/api";

export enum ETaskLocalState {
  CREATED = 'CREATED',  // The user created a new Task (object created on the client side)
  SERV_UNAVAILABLE = 'SMART_UNAVAILABLE',  // The SMART API is unavailable (request returned HTTP error)
  REQUEST_ERROR = 'REQUEST_ERROR',  // The SMART API rejected the request
}

export type TTaskState = ETaskLocalState | ETaskStatus;

export const TaskFinalStates: Set<string> = new Set([
  ETaskStatus.COMPLETED,
  ETaskStatus.FAILED,
  ETaskStatus.CANCELLED,
]);

export interface ITask {
  uuid: string; // Until a real UUID is known from backend, use creation timestamp instead
  name: string;  // Human-readable task name (user-supplied)
  priority: ETaskPriority;
  flavor: ETaskFlavor;
  payload: ITaskPayload;
  timeCreated: string;
  status: TTaskState;
  statusMessage?: string;
  timeStatusChanged: string;
  owner?: string;
  group?: string;
  log: ITaskResponse[];
}

export function taskFromITaskResponse(taskResponse: ITaskResponse): ITask {
  return {
    uuid: taskResponse.uuid,
    name: taskResponse.name,
    flavor: taskResponse.flavor,
    priority: taskResponse.priority,
    payload: taskResponse.payload,
    timeCreated: taskResponse.timeSent,
    timeStatusChanged: taskResponse.timeProcessed,
    status: taskResponse.status.status,
    log: []
  }
}
