/**
 * Contains all the API definitions of Malleus maleficarum
 */

export const BanningService = {
  "type": "rest",
  "commands": {
    "services": {
      "httpMethod": "get",
      "endpoint": "/services"
    },
    "bans:get": {
      "httpMethod": "get",
      "endpoint": "/bans"
    },
    "bans:create": {
      "httpMethod": "post",
      "endpoint": "/bans",
      "body": `{
        "targetIdentity": "{user}",
        "serviceIds": {services},
        "expiration": "{expiration}",
        "reason": "{reason}"
      }`
    },
    "bans:terminate": {
      "httpMethod": "put",
      "endpoint": "/bans/{id}",
      "body": `{
        "reason": "{reason}"
      }`
    }
  }
}

/**
 * Service record in Malleus maleficarum
 *
 * Represents a service, which can be banned.
 */
export interface IBanningService {
  id: number,
  name: string,
}

/**
 * Ban record in Malleus maleficarum
 *
 * Represents a single ban of a service
 */
export interface IBanningBan {
  id: number,
  target: IBanningPerson,
  created_at: string,
  expiration: string,
  creator: IBanningPerson,
  state: EBanningBanState,
  reason_banned: string,
  terminator: IBanningPerson,
  reason_unbanned: string,
  service: IBanningService,
}

/**
 * Shortened ban record in Malleus maleficarum
 *
 * Same as IBanningBan, but IBanningPerson records shortened to UČO strings.
 */
export interface IBanningBanShort {
  id: number,
  target_identity: string,  // UČO of banned person (or guest format)
  created_at: string,
  expiration: string,
  creator: number,  // UČO of the person who issued the ban
  state: EBanningBanState,
  reason_banned: string,
  terminator: number, // UČO of the person who cancelled the ban
  reason_unbanned: string,
  service: IBanningService,
}

/**
 * Person record in malleus maleficarum
 */
export interface IBanningPerson {
  uin: number,
  firstname: string,
  lastname: string,
}

/**
 * List of all states of the ban
 *
 * During its life cycle, the ban may take on the following states.
 */
export enum EBanningBanState {
  RUNNING = "Running",
  TERMINATED = "Terminated",
  EXPIRED = "Expired",
}

/**
 * Error report from Malleus maleficarum
 */
export interface IBanningError {
  type?: string,
  title?: string,
  status?: number,
  detail?: string,
  instance?: string,
}
