import { ITask } from "../../util/task";

export interface TasksState {
  notifications: string[],  // UUID of a task which has any unseen status change
                            // Set<string> would be better, but is not serializable nor immutable (NgRx requirements)
  tasks: {[uuid: string]: ITask}, // Active tasks indexed by their UUID
                                          // Map<string, ITaskResponse> would be better,
                                          // but is not serializable nor immutable (NgRx requirements)
}

export const initialTasksState: TasksState = {
  notifications: [],
  tasks: {},
}
