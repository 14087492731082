import { Injectable, isDevMode } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  catchError,
  defer,
  interval,
  map,
  Observable,
  Observer,
  of,
  ReplaySubject,
  startWith,
  Subject,
  switchMap
} from "rxjs";
import { Store } from "@ngrx/store";

import {
  EAssetType,
  ETaskCredentials,
  ETaskFlavor,
  ETaskPriority,
  ETaskStatus, HealthStatusUnavailable,
  IAsset,
  IAssetName,
  IAssetRequest, ICommandParameters, IError,
  IHealthStatus, IResponse, isError,
  isSMARTError,
  ITaskRequest,
  ITaskResponse,
} from "../api";
import api from "../api";

import { environment } from "../../../environments/environment";
import { TaskCreated, TaskRequestError, TaskServUnavailable } from "../../core/store/tasks";
import { ETaskLocalState, ITask, taskFromITaskResponse } from "../../core/util/task";
import { NotificationNew } from "../../core/store/notifications";
import { AuthService } from "../../core/services/auth/auth.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { ConnectedServiceError } from "../exceptions";

@Injectable({
  providedIn: 'root'
})
export class SmartApiService {

  readonly apiURL: URL = new URL(environment.apiURL);
  readonly notifications$: Subject<any> = new Subject<any>();

  private health: ReplaySubject<IHealthStatus> = new ReplaySubject<IHealthStatus>(1);
  readonly health$: Observable<IHealthStatus> = defer(() => this.health.asObservable());

  private headers: HttpHeaders = new HttpHeaders({
    'Authorization': `Bearer ${this.auth.accessToken}`,
    'Accept': 'application/json'
  });

  /**
   * Creates a new SMART API service and connects it to the backend.
   *
   * @param store Injected NgRx Store to update internal state based on the API.
   * @param http Injected HTTP Client to make REST calls.
   * @param auth Injected AuthService containing identity and authorization data (OIDC wrapper)
   * @param translate Injected TranslateService used for localised error notification messages
   * @param notification  Injected NzNotificationService used for error notification messages
   *
   * @throws TypeError API URL from environment.apiURL cannot be parsed.
   */
  constructor(
    private store: Store,
    private http: HttpClient,
    private auth: AuthService,
    private translate: TranslateService,
    private notification: NzNotificationService
  ) {

    // Listen to the access token change and update the headers
    this.auth.accessToken$.subscribe(
      (token) => {
        this.headers = this.headers.set("Authorization", `Bearer ${token}`);
      }
    );

    // API Health stream
    interval(10000) // Update every 10 seconds
      .pipe(
        // Early HTTP call to speed up the initialization
        startWith(
          this.http.get<IHealthStatus>(this.apiURL + 'health').pipe(
            catchError(() => of(HealthStatusUnavailable))
          )
        ),
        // SwitchMap to cancel the HTTP call if it takes longer than interval and tries again
        switchMap(
          () => this.http.get<IHealthStatus>(this.apiURL + 'health').pipe(
            catchError(() => of(HealthStatusUnavailable))
          )
        )
      )
      .subscribe(this.health);

/*  Websocket testing
    // Websocket Notifications
    let wsURL = new URL(this.apiURL);
    wsURL.protocol = 'ws';
    // TODO: Use wsURL or real WS URL
    let ws = new WebSocket("wss://demo.piesocket.com/v3/channel_123?api_key=VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV");
    let ws$ = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    ws$.pipe(
      // TODO: Convert to standardized interface
      map(
        (msg: MessageEvent) => {
          return msg.data
        }
      )
    ).subscribe(this.notifications$);

    // TODO: Remove debug function
    this.debugWS();
*/
  }

  /** General functionality **/

  /* Section: TODO: New backend */

  /**
   * Simple interpolation function, shall be moved to generic API and not here.
   */
  private interpolate(template: string, variables: { [key: string]: any }): string {
    return template.replace(/{(\w+)}/g, (match, key) => {
      return key in variables ? String(variables[key]) : match;
    });
  }

  /**
   * Perform a direct call to the SMART API and return the API response as observable.
   *
   * @param serviceID The external service, which shall be called by SMART.
   * @param commandID The command identifier, which defines which task will be performed at the target service.
   * @param parameters An object with parameters of the command.
   */
  private directCall(
    serviceID: string,
    commandID: string,
    parameters: ICommandParameters = {}
  ): Observable<IResponse> {
    if (isDevMode()) console.group("SMART API Call");

    let service = api[serviceID];
    if (service === undefined) {
      this.notification.error(
        this.translate.instant('notifications.error'),
        "Unknown service called"
      );
      throw "Error";  // TODO: Proper Exception
    }

    let command = service['commands'][commandID];
    if (command === undefined) {
      this.notification.error(
        this.translate.instant('notifications.error'),
        "Unknown command called"
      );
      throw "Error";  // TODO: Proper Exception
    }
    if (isDevMode()) console.debug(command);

    let endpoint = this.interpolate(command['endpoint'], parameters);
    if (endpoint[0] == '/')
      endpoint = endpoint.substring(1);
    if (isDevMode()) console.debug(endpoint);

    let params: ICommandParameters = {};
    if (command['queryParameters'] !== undefined) {
      params = command['queryParameters'].reduce(
        (params: ICommandParameters, key: string) => {
          if (key in parameters) {
            params[key] = String(parameters[key])
          }
          return params;
        },
        params
      )
    }
    if (isDevMode()) console.debug(params);

    // if (hasBody(task_config)) { body = command_to_body(config, parameters) }
    let body: string = "";
    if (command['body'] !== undefined) {
      body = this.interpolate(command['body'], parameters);
      if (isDevMode()) console.debug(body);
    }

    let url: string = `${this.apiURL.toString()}${serviceID}/${endpoint}`;

    if (isDevMode()) console.groupEnd();

    switch (command['httpMethod'].toUpperCase()) {
      case 'POST':
        return this.http.post<IResponse>(url, body, {
          headers: this.headers.set("Content-Type", "application/json"),
          params: params
        });
      case 'GET':
        return this.http.get<IResponse>(url, {
          headers: this.headers,
          params: params
        });
      case 'PUT':
        return this.http.put<IResponse>(url, body, {
          headers: this.headers.set("Content-Type", "application/json"),
          params: params
        });
      case 'DELETE':
        return this.http.delete<IResponse>(url, {
          headers: this.headers,
          params: params
        });
      default:
        this.notification.error(
          this.translate.instant('notifications.error'),
          "Unknown HTTP method called"
        );
        throw "Error";  // TODO: Proper Exception
    }
  }

  /**
   * Perform a call to the defined service via SMART API and return the unpacked response as observable.
   *
   * Error is checked and depending on the selected notification level the notification is shown to the user.
   *
   * @param serviceID The external service, which shall be called by SMART.
   * @param commandID The command identifier, which defines which task will be performed at the target service.
   * @param parameters An object with parameters of the command.
   * @param notificationLvl The level of notifications, which shall be displayed to the user. By default, all errors are reported.
   */
  public call(
    serviceID: string,
    commandID: string,
    parameters: ICommandParameters = {},
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ): Observable<any> {
    return this.directCall(serviceID, commandID, parameters).pipe(
      catchError((error) => {
        if (isError(error)) {
          if (notificationLvl <= SmartApiService.NotificationLevel.HttpOnly) {
            this.notification.error(
              this.translate.instant('notifications.httpError', {'statusCode': error.status.toString()}),
              this.translate.instant(`error.${error.status}.title`)
            );
          }
          if (isDevMode()) {
            console.group('HTTP Error Response from SMART API');
            console.error(error);
            console.groupEnd();
          }
        }
        throw error;  // Http
      }),
      map((response: IResponse) => {
        // TODO: Error checks
        try {
          return JSON.parse(response['response']);
        } catch (err) {  // If it didn't parse as JSON, it probably is just a plain string, so return that
          return response['response'];
        }
      })
    );
  }

  /* Section: Tasks */

  /**
   * Handles the HTTP request to the SMART Server and notifies about errors
   *
   * @param name  Human-readable identifier of the task
   * @param endpoint  UUID of the task endpoint
   * @param flavor  Type of the request
   * @param command   Command that shall be performed on the endpoint
   * @param parameters  List of the command parameters, default is empty list
   * @param priority  Task priority, default is NORMAL
   * @param credential  Credential that shall be used for the authorization to the endpoint (default is JWT passthrough)
   * @param notificationLvl Allows for overriding how the notifications are displayed. By default, all notifications are displayed.
   * @private
   */
  private createSMARTTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    priority: ETaskPriority = ETaskPriority.NORMAL,
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ): Observable<ITaskResponse> {

    let taskReq: ITaskRequest = {
      name: name,
      flavor: flavor,
      priority: priority,
      payload: {
        endpoint: endpoint,
        credential: credential,
        command: command,
        arguments: parameters
      },
    };

    return this.http
      .post<ITaskResponse>(this.apiURL.toString() + 'task-dispatcher/task', taskReq, {headers: this.headers})
      .pipe(
        catchError((error, caught) => {
          if (notificationLvl <= SmartApiService.NotificationLevel.HttpOnly) {
            this.notification.error(
              this.translate.instant('notifications.httpError', {'statusCode': error.status.toString()}),
              this.translate.instant(`error.${error.status}.title`)
            );
          }
          if (isDevMode()) {
            console.group('HTTP Error Response from SMART API');
            console.error(error);
            console.groupEnd();
          }
          throw error;  // Http
          return caught;  // Never happens, but the lint is happier
        }),
        map(response => {
          switch (response.status.status) {
            case ETaskStatus.FAILED:
            case ETaskStatus.NOTFOUND:
            case ETaskStatus.UNKNOWN:
              if (notificationLvl <= SmartApiService.NotificationLevel.All) {
                this.notification.error(
                  this.translate.instant('notifications.serviceError'),
                  response.status.message
                );
              }
              if (isDevMode()) {
                console.group('Connected service error');
                console.error(response);
                console.groupEnd();
              }
              throw ConnectedServiceError.fromTaskResponse(response);
            default:
              return response
          }
        })
      );

  }

  public createTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    priority: ETaskPriority = ETaskPriority.NORMAL,
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ): Observable<any> {

    return this.createSMARTTask(
      name,
      endpoint,
      flavor,
      command,
      parameters,
      priority,
      credential,
      notificationLvl
    ).pipe(
      map(response => {
        if (response.result['resultJson'] == '')
          return null;

        try {
          return JSON.parse(response.result['resultJson']);
        } catch (e) {  // If it didn't parse as JSON, it probably is just a plain string, so return that
          return response.result['resultJson'];
        }
      })
    )

  }

  public createDeferredTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    priority: ETaskPriority = ETaskPriority.NORMAL,
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH
  ) {

    let timestamp = new Date();

    let task:ITask = {
      uuid: timestamp.getTime().toString(),
      name: name,
      priority: priority,
      flavor: flavor,
      payload: {
        endpoint: endpoint,
        credential: credential,
        command: command,
        arguments: parameters
      },
      timeCreated: timestamp.toISOString(),
      status: ETaskLocalState.CREATED,
      timeStatusChanged: timestamp.toISOString(),
      log: []
    };
    this.store.dispatch(TaskCreated(task));

    let apiResponseObserver: Observer<ITaskResponse> = {
      next: response => this.store.dispatch(NotificationNew({
        uuid: response.uuid,
        name: response.name,
        lastUpdate: response.timeProcessed,
        status: response.status.status
      })),
      error: (response: HttpErrorResponse) => {
        if (isSMARTError(response.error)) {
          this.store.dispatch(TaskRequestError({
            uuid: task.uuid,
            error: response.error.message
          }))
        } else {
          this.store.dispatch(TaskServUnavailable({
            uuid: task.uuid,
            error: 'error.' + response.status.toString() + '.title'
          }))
        }
      },
      complete: () => {}
    }

    this.createSMARTTask(
      task.name,
      task.payload.endpoint,
      task.flavor,
      task.payload.command,
      task.payload.arguments,
      task.priority,
      task.payload.credential,
      SmartApiService.NotificationLevel.None  // Error notifications handled in RxJs store
    ).subscribe(apiResponseObserver)

  }

  public createUiTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ) {

    // Same as realtime task for now, shall be distinct in the future
    return this.createTask(
      name,
      endpoint,
      flavor,
      command,
      parameters,
      ETaskPriority.REALTIME,
      credential,
      notificationLvl
    );

  }

  public createRealtimeTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ) {

    return this.createTask(
      name,
      endpoint,
      flavor,
      command,
      parameters,
      ETaskPriority.REALTIME,
      credential,
      notificationLvl
    );

  }

  /**
   * Retrieves a list of tasks.
   */
  public getTasks(): Observable<ITask[]> {

    return this.http
      .get<ITaskResponse[]>(this.apiURL.toString() + 'task-dispatcher/tasks', {headers: this.headers})
      .pipe(
        // Remap the ITaskResponse[] API object to ITask[] internal object
        map((responses: ITaskResponse[]) => responses.map(taskFromITaskResponse))
      )

  }

  /**
   * Retrieves detail information about the task specified by UUID.
   *
   * @param uuid of the task
   */
  public getTask(uuid: string): Observable<ITask> {

    let params = new HttpParams().set('taskId', uuid);

    return this.http
      .get<ITaskResponse>(
        this.apiURL.toString() + 'task-dispatcher/task',
        {headers: this.headers, params: params}
      ).pipe(
        // Remap the ITaskResponse API object to ITask internal object
        map((response: ITaskResponse) => taskFromITaskResponse(response))
      )

  }

  /* Section: Assets */

  /**
   * Retrieves a list of assets. If filter is specified, return only assets with wanted UUIDs.
   *
   * @param filter Array of UUIDs which shall be retrieved.
   */
  public getAssets(filter?: string[]): Observable<IAsset[]> {

    if (typeof filter === 'undefined') {
      return this.http.get<IAsset[]>(this.apiURL.toString() + 'asset-service/assets', {headers: this.headers});
    } else {
      let params = new HttpParams({
        fromObject: {'assetIds': filter}
      });

      return this.http.get<IAsset[]>(
        this.apiURL.toString() + 'asset-service/assetsById',
        {headers: this.headers, params: params}
      );
    }
  }

  /**
   * Deletes an asset identified by its UUID.
   *
   * @param uuid of the asset
   */
  public deleteAsset(uuid: string): Observable<IAssetName> {

    let params = new HttpParams().set('assetId', uuid);

    return this.http.delete<IAssetName>(
      this.apiURL.toString() + 'asset-service/asset',
      {headers: this.headers, params: params}
    );
  }


  /**
   * Creates an asset.
   *
   * @param request data of the asset which shall be created
   */
  public createAsset(request: IAssetRequest): Observable<IAssetName> {
    return this.http.post<IAssetName>(
      this.apiURL.toString() + 'asset-service/asset',
      request,
      {headers: this.headers}
    );
  }

  /**
   * Retrieves a list of credentials.
   */
  public getCredentials(): Observable<IAsset[]> {
    return this.getAssets().pipe(
      map(assets => assets.filter(asset => asset.AssetType === EAssetType.CREDENTIAL))
    );
  }

  /**
   * Retrieves a list of endpoints.
   */
  public getEndpoints(): Observable<IAsset[]> {
    return this.getAssets().pipe(
      map(assets => assets.filter(asset => asset.AssetType === EAssetType.ENDPOINT))
    );
  }

  /** Utilities **/

  private debugWS() {
    this.notifications$.subscribe({
      next: (data) => { console.debug("WS: " + data) },
      error: (data) => { console.error("WS: " + data); },
      complete: () => { console.log("WS: Connection closed") },
    });
  }

}
// Declaration merging to add class enum (https://www.typescriptlang.org/docs/handbook/declaration-merging.html#merging-namespaces-with-classes)
export namespace SmartApiService {

  export enum NotificationLevel {
    All,  // Display error notifications for both HTTP errors and Connected Service errors
    HttpOnly, // Display error notifications only for the HTTP errors (errors between Smart Server and WebUI)
    None, // Do not display any error notifications
  }

}
