/**
 * Contains the API definitions of Reservation Service
 */

export const ReservationService = {
  "type": "rest",
  "commands": {
    "localities:get": {
      "httpMethod": "get",
      "endpoint": "/localities"
    },
    "reservations:get": {
      "httpMethod": "get",
      "endpoint": "/reservations",
      "queryParameters": ["localityId", "from", "to", "sortDescending"]
    },
    "reservation:post": {
      "httpMethod": "post",
      "endpoint": "/reservations",
      "body": `{
        "mapLocalityId": {locality},
        "mapStudyroomId": {studyroom},
        "reservedFrom": "{from}",
        "reservedTo": "{to}",
        "note": "{note}"
      }`
    },
    "reservation:put": {
      "httpMethod": "put",
      "endpoint": "/reservations/{id}"
    },
    "reservation:delete": {
      "httpMethod": "delete",
      "endpoint": "/reservations/{id}"
    }
  }
}

export interface IReservationLocality {
  id: number;
  name?: string;
  studyrooms?: IReservationStudyroom[];
}

export interface IReservationStudyroom {
  id: number;
  name?: string;
  localityId?: number;
}

export interface IReservationReservation {
  id: number;
  mapLocalityId: number;
  mapStudyroomId?: number;
  reservedFrom: string;
  reservedTo: string;
  cancelled: boolean;
  note?: string;
}
