import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'localisedDate',
  pure: false
})
export class LocalisedDatePipe implements PipeTransform {

  private value: string = '';
  private lastLang: string = ''; // required to update the value when currentLang is changed
  private lastDate: string = '';
  private lastPattern: string = 'mediumDate';

  constructor(private translate: TranslateService) { }

  transform(date: string, pattern: string = 'mediumDate'): string {
    const currLang = this.translate.currentLang || 'en-US';

    // if we ask another time for the same locale & date, return the last value
    if ((currLang === this.lastLang) && (date === this.lastDate) && (pattern === this.lastPattern)) {
      return this.value;
    }

    this.value = new DatePipe(currLang).transform(date, pattern) || date;

    this.lastLang = currLang;
    this.lastDate = date;
    this.lastPattern = pattern;

    return this.value;
  }

}
