import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { AntdModule } from "./antd.module";

import { Error404Component } from "./components/error/404/404.component";
import { Error500Component } from "./components/error/500/500.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { NewEntityWizardInputsComponent } from "./components/new-entity-wizard-inputs/new-entity-wizard-inputs.component";
import { NewEntityWizardOutputsComponent } from "./components/new-entity-wizard-outputs/new-entity-wizard-outputs.component";
import { UserAclListingComponent } from "./components/user-acl-listing/user-acl-listing.component";
import { FirstLetterCapitalPipe } from "./pipes/FirstLetterCapitalPipe";
import { LocalisedDatePipe } from "./pipes/LocalisedDatePipe";
import { Error403Component } from "./components/error/403/403.component";


@NgModule({
    imports: [
        AntdModule,
        CommonModule,
        TranslateModule,
    ],
  declarations: [
    Error403Component,
    Error404Component,
    Error500Component,
    FirstLetterCapitalPipe,
    LocalisedDatePipe,
    SpinnerComponent,
    NewEntityWizardInputsComponent,
    NewEntityWizardOutputsComponent,
    UserAclListingComponent,
  ],
  exports: [
    AntdModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    Error404Component,
    Error500Component,
    FirstLetterCapitalPipe,
    LocalisedDatePipe,
    SpinnerComponent,
    NewEntityWizardInputsComponent,
    NewEntityWizardOutputsComponent,
    UserAclListingComponent,
  ]
})
export class SharedModule { }
