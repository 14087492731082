import {NgModule, ModuleWithProviders, Optional, SkipSelf, APP_INITIALIZER, isDevMode} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";


import { OAuthModule } from "angular-oauth2-oidc";
import { AuthService } from "./services/auth/auth.service";
import { ACLComponent } from "./components/ACL/acl.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";

import { LayoutModalComponent } from "./components/layouts/layout-modal/layout-modal.component";
import { LayoutPlainComponent } from './components/layouts/layout-plain/layout-plain.component';
import { LayoutBreadcrumbsComponent } from './components/layouts/layout-breadcrumbs/layout-breadcrumbs.component';

import { TopbarComponent } from "./components/topbar/topbar.component";
import { ActiveTasksMenuComponent } from "./components/topbar/active-tasks-menu/active-tasks-menu.component";
import { BookmarksComponent } from "./components/topbar/bookmarks/bookmarks.component";
import { SearchComponent } from "./components/topbar/search/search.component";
import { UserMenuComponent } from "./components/topbar/user-menu/user-menu.component";

import { MainMenuComponent } from './components/main-menu/main-menu.component';

import { SidebarComponent } from "./components/sidebar/sidebar.component";

import { FooterComponent } from "./components/footer/footer.component";

import { LoginComponent } from "./components/login/login.component";
import { Error500Component } from "../shared/components/error/500/500.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UserSettingsComponent } from './components/user-settings/user-settings.component';

import { ResultContentComponent } from "./components/topbar/search/result-content/result-content.component";
import { ActiveTasksListComponent } from './components/tasks/active-tasks-list/active-tasks-list.component';
import { UserTasksListComponent } from './components/tasks/user-tasks-list/user-tasks-list.component';
import { TaskDetailComponent } from './components/tasks/task-detail/task-detail.component';
import { UserTasksComponent } from './components/tasks/user-tasks/user-tasks.component';
import { UserWelcomeComponent } from './components/dashboard/user-welcome/user-welcome.component';
import { UserStatisticsComponent } from './components/dashboard/user-statistics/user-statistics.component';
import { SmartStatusComponent } from './components/dashboard/smart-status/smart-status.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { BreadcrumbComponent, BreadcrumbItemDirective } from "xng-breadcrumb";

// We need a factory since localStorage is not available at AOT build time
// export function storageFactory(): OAuthStorage {
//  return localStorage;
// }

function initializeApp(authService: AuthService): () => Promise<boolean> {

  return () => authService.runInitialLogin().catch((error) => {

    // Log error
    if (isDevMode()) {
      console.error("Internal Server ERROR: " + error);
    } else {
      console.error("Internal Server ERROR");
    }

    // Display error message instead of loading
    Error500Component.overwriteAppWithErrorPage("Connection to the Authentication Server failed. Please, try again later.");

    // Prevent App loading
    return Promise.reject(error);
  } );

}

@NgModule({
  declarations: [
    ACLComponent,
    TopbarComponent,
    BookmarksComponent,
    ResultContentComponent,
    SearchComponent,
    SidebarComponent,
    FooterComponent,
    LayoutModalComponent,
    LayoutPlainComponent,
    LayoutBreadcrumbsComponent,
    LoginComponent,
    DashboardComponent,
    NotificationsComponent,
    UserMenuComponent,
    MainMenuComponent,
    UserSettingsComponent,
    ActiveTasksMenuComponent,
    ActiveTasksListComponent,
    UserTasksListComponent,
    TaskDetailComponent,
    UserTasksComponent,
    UserWelcomeComponent,
    UserStatisticsComponent,
    SmartStatusComponent,
    LanguageSwitchComponent,
  ],
  imports: [
      CommonModule,
      SharedModule,
      OAuthModule.forRoot(),
      RouterModule,
      FormsModule,
      BreadcrumbComponent, BreadcrumbItemDirective
  ],
  exports: [
    LayoutModalComponent,
    LayoutPlainComponent,
    LayoutBreadcrumbsComponent,
    LoginComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService], multi: true },
//        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
