import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { Logout, selectUserDetails } from "../../../store/user";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'smart-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  user$ = this.store.select(selectUserDetails);

  settingsVisible = false;

  links: string[] = [
    '/my-tasks'
  ];

  public userMenuAvatarClass: string = "";

  constructor(
    private store: Store<any>,
    private router: Router
  ) {
    router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        if (this.links.includes(route.urlAfterRedirects)) {
          this.userMenuAvatarClass = "user-menu-selected";
        } else {
          this.userMenuAvatarClass = "";
        }
      }
    });
  }

  ngOnInit(): void {
  }

  showSettings() {
    this.settingsVisible = true;
  }

  hideSettings() {
    this.settingsVisible = false;
  }

  logout() {
    this.store.dispatch(Logout());
  }

}
