import { ITaskResponse } from "../api";

export class ConnectedServiceError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ConnectedServiceError.prototype); // Required, otherwise the instance would be 'Error'
  }

  // TODO: Would be preferable to add the identifier of the service (new SMART API)
  public static fromTaskResponse(response: ITaskResponse): ConnectedServiceError {
    return new ConnectedServiceError(response.status.message);
  }
}
