<section id="error">
  <h1>{{'error.403.header' | translate}}</h1>
  <h2>— 403 —</h2>

  <p>{{'error.403.explanation' | translate}}</p>

  <a class="btn btn-outline-primary" (click)="goBack()">
    {{'generic.back' | translate | firstLetterCapital}}
  </a>
</section>
