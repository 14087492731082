<section id="error">
  <h1>{{'error.500.header' | translate}}</h1>
  <h2>— 500 —</h2>

  <p>{{'error.500.explanation' | translate}}</p>

  <a class="btn btn-outline-primary" href="javascript:window.location.reload()">
    {{'generic.try_again' | translate | firstLetterCapital}}
  </a>
</section>

