import { Component, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import * as Reducers from "../../store/reducers";
import * as SettingsActions from "../../store/settings/actions";

const DEFAULT_PRIMARY_COLOR = '#0000dc';

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  locale!: string;
  theme!: string;
  primaryColor!: string;

  constructor(private store: Store) {
    this.store.pipe(select(Reducers.getSettings)).subscribe( state => {
      this.locale = state.settings.locale;
      this.theme = state.settings.theme;
      this.primaryColor = state.settings.primaryColor;
    });
  }

  ngOnInit(): void {
  }

  changeLocale(locale: string) {
    this.store.dispatch(
      SettingsActions.SwitchLocale({'locale': locale})
    )
  }

  changeTheme(theme: string) {
    this.store.dispatch(
      SettingsActions.SetTheme({'theme': theme})
    )
  }

  changePrimaryColor(color: string) {

    // TODO: Move to Settings store effects when refactoring Settings Store
    let root = document.querySelector<HTMLElement>(':root');
    root?.style.setProperty('--kit-color-primary', color);

    this.store.dispatch(
      SettingsActions.SetThemeColor({primaryColor: color}),
    )
  }

  resetPrimaryColor() {
    this.changePrimaryColor(DEFAULT_PRIMARY_COLOR);
  }
}
