/**
 * Contains all the API definitions of Person Manager 2
 */

export const PersonService = {
  "type": "rest",
  "commands": {
    "person:get": {
      "httpMethod": "get",
      "endpoint": "/{uin}/details"
    }
  }
}

export interface IPersonDetails {
  uin: number,  // UČO
  name: string,
  titleBefore: string | null,
  firstName: string,
  lastName: string,
  titleAfter: string | null,
  photo: string,  // Base64 encoded JPEG
  cardNumber: string, // Hexadecimal number of the ID card
  active: boolean,
  notes: IPersonNotes[],
  studies: IPersonStudies[],
  jobs: IPersonJob[],
}

export interface IPersonNotes {
  timestamp: string,
  uin: number,  // UČO of the person the note refers to
  creator: number,  // UČO of the person who added the note
  text: string, // The note itself
}

export interface IPersonStudies {
  facultyId: number,
  faculty: string,
  name: string,
  activeValue: symbol,  // Symbol?
}

export interface IPersonJob {
  title: string,
}
