/**
 * Contains all the API definitions of Occupation Service
 */

export const OccupationService = {
  "type": "rest",
  "commands": {
    "closure:delete": {
      "httpMethod": "delete",
      "endpoint": "/closures/{id}"
    },
    "closure:end": {
      "httpMethod": "put",
      "endpoint": "/closures/{locality}"
    },
    "closure:create": {
      "httpMethod": "post",
      "endpoint": "/closures",
      "body": `{
        "localityId": {locality},
        "intervalWithSchedule": {
          "valid_from": "{from}",
          "valid_to": "{to}"
        }
      }`
    },
    "closure:create_unlimited": {
      "httpMethod": "post",
      "endpoint": "/closures",
      "body": `{
        "localityId": {locality},
        "intervalWithSchedule": {
          "valid_from": "{from}"
        }
      }`
    },
    "localities:get": {
      "httpMethod": "get",
      "endpoint": "/localities"
    },
    "schedule:delete": {
      "httpMethod": "delete",
      "endpoint": "/schedules/{id}"
    },
    "schedule:create": {
      "httpMethod": "post",
      "endpoint": "/schedules",
      "body": `{
        "localityId": {locality},
        "intervalWithSchedule": {
          "valid_from": "{from}",
          "valid_to": "{to}",
          "schedule": {schedule},
          "endOtherDaysInSchedule": {emptyDaysUseCurrent}
        }
      }`
    },
    "schedule:create_unlimited": {
      "httpMethod": "post",
      "endpoint": "/schedules",
      "body": `{
        "localityId": {locality},
        "intervalWithSchedule": {
          "valid_from": "{from}",
          "schedule": {schedule},
          "endOtherDaysInSchedule": {emptyDaysUseCurrent}
        }
      }`
    }
  }
}

/**
 * Information about public computer studyroom
 */
export interface IOccupationLocalityInfo {
  id: number,  // Locality Id
  name_cs: string,  // Czech locality name
  name_en: string,  // English locality name
  address: string,  // Locality address
  link: string,  // Web address
  available_computers: number,  // Current count of available computers
  total_computers: number,  // Total count of computers
  turnstile_info: IOccupationTurnstile,  // Turnstile
  schedules: IOccupationIntervalWithSchedule[],  // Schedules
  changes: IOccupationDateInterval[],  // Holiday schedules
  closures: IOccupationDateInterval[],  // Range when the computer room is closed
  additional_services: EOccupationAdditionalServices[],
  location_codes: {  // List of locality location codes
    computer_study_rooms: string[],
    entry_rooms: string[],
  },
}

/**
 * Schedule type
 */
export enum EOccupationScheduleType {
  SCHEDULE = 0,
  CLOSURE = 1
}


/**
 * Turnstile
 */
export interface IOccupationTurnstile {
  count: number,  // Current number of people in room
  limit: number,  // Maximal limit of studyroom
}

/**
 * Interval with date and schedule
 */
export interface IOccupationIntervalWithSchedule {
  id?: number,
  valid_from: string,  // From date
  valid_to?: string,  // To date
  schedule?: {
    sunday?: IOccupationTimeInterval[],
    monday?: IOccupationTimeInterval[],
    tuesday?: IOccupationTimeInterval[],
    wednesday?: IOccupationTimeInterval[],
    thursday?: IOccupationTimeInterval[],
    friday?: IOccupationTimeInterval[],
    saturday?: IOccupationTimeInterval[]
  }
}

/**
 * Time Interval
 */
export interface IOccupationTimeInterval {
  from: string,  // From time
  to: string  // To time
}

/**
 * Date interval
 */
export interface IOccupationDateInterval {
  id: number,
  from: string,  // From date
  to?: string  // To date
}

/**
 * Additional services enum
 */
export type EOccupationAdditionalServices = 'Printing' | 'DocumentBinding' | 'SellingOfficeSupplies' | 'Laminating';
