/**
 * Contains all the API definitions of REST Zappa
 */

export const ZappaService = {
  "type": "rest",
  "commands": {
    "localities:get": {
      "httpMethod": "get",
      "endpoint": "/localities"
    },
    "locality:get": {
      "httpMethod": "get",
      "endpoint": "/localities/{id}"
    }
  }
}

/**
 * Overview of a locality
 */
export interface IZappaLocality {
  id: number,
  name: string,
  computers_count: number,
  statuses: IZappaLocalityStatusCount[]
}

/**
 * Locality details
 */
export interface IZappaLocalityDetail {
  id: number,
  name: string,
  studyrooms: IZappaStudyroom[],
}

/**
 * Summarized counters for overview of locality
 */
interface IZappaLocalityStatusCount {
  status: EZappaComputerStatus,
  count: number
}

/**
 * Represents a study room with computers
 */
export interface IZappaStudyroom {
  name: string,
  computers: IZappaComputer[],
}

/**
 * Represents a single computer
 */
export interface IZappaComputer {
  id: number,
  distinguished_name: string,
  fqdn: string,
  name: string,
  uin?: number, // UCO of the logged-in user
  status: EZappaComputerStatus,
}

/**
 * Enum of computer states
 */
export type EZappaComputerStatus = 'PowerOff' | 'PowerOn' | 'LoggedIn' | 'UserLeft' | 'MultiLog' | 'External' | 'Maintenance' | 'Unknown';
