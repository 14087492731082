import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { BookmarksState, selectBookmarksState, BookmarkAdded, BookmarkRemoved } from "../../../store/bookmarks";

@Component({
  selector: 'smart-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {

  bookmarks: BookmarksState = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store) {

    this.store.select(selectBookmarksState).subscribe( state => this.bookmarks = state );
  }

  ngOnInit(): void {  }

  addBookmark(
    link: string = this.getCurrentLink(),
    title: string | undefined = this.getCurrentTitle()
  ) {

    if (title !== undefined) {
      this.store.dispatch(BookmarkAdded({
        name: title,
        link: link
      }));
    }
  }

  removeBookmark(
    link: string = this.getCurrentLink(),
    title: string | undefined = this.getCurrentTitle()
  ) {

    title = (title !== undefined) ? title : '';

    this.store.dispatch(BookmarkRemoved({
      name: title,
      link: link,
    }));
  }

  isBookmarked(link: string = this.getCurrentLink()) : boolean {
    return ( this.bookmarks.find(item => item.link === link ) !== undefined );
  }

  getCurrentLink(): string {
    return this.router.url;
  }

  getCurrentTitle(): string | undefined {

    let title = undefined
    let webTitle = document.title;

    if (webTitle) {
      let pipePos = webTitle.indexOf('|')
      if (pipePos > 0) { // If the | is found on the first position, title is invalid anyways
        title = webTitle.substring(0, pipePos).trim()
      }
    }

    return title;
  }
}
