import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';

@Component({
  selector: 'smart-error-403',
  templateUrl: './403.component.html',
})
export class Error403Component implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    const replaceUrl = this.activatedRoute.snapshot.queryParams['url'];
    if (replaceUrl)
      this.location.replaceState(replaceUrl);
  }

  goBack() {
    history.back();
  }
}
