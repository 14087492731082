<nav id="main-menu">
    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="collapsed" class="{{collapsed ? 'menu-collapsed' : ''}}">

      <!-- Unpacked ngFor so we can display different templates based on item type -->
      <ng-template ngFor let-item [ngForOf]="contents">


        <ng-container *ngTemplateOutlet="isMenuSection(item) ? menuSection : menuLink; context: { $implicit: item }">
        </ng-container>

      </ng-template>

<!-- Menu Section -->
<ng-template #menuSection let-section>
  <li nz-menu-group nzTitle="{{section.title | translate}}"><ul>

    <!-- Unpacked ngFor so we can display different templates based on item type -->
    <ng-template ngFor let-item [ngForOf]="section.items">

      <ng-container *ngTemplateOutlet="isMenuCategory(item) ? menuCategory : menuLink; context: { $implicit: item }">
      </ng-container>

    </ng-template>
  </ul></li>
</ng-template>

<!-- Menu Category -->
<ng-template #menuCategory let-category>
  <li nz-submenu *ngIf="!category.hasOwnProperty('restrictTo') || auth.authorizeResource(category.restrictTo)"
      [nzTitle]="menuCategoryTitle">
    <ng-template #menuCategoryTitle>
            <span title class="menu-icon-item">
              <span class="icon {{category.icon}}"></span>
              <span class="text category-title" nz-typography nzEllipsis="true">
                {{category.title | translate}}
              </span>
            </span>
    </ng-template>
    <ul>

      <ng-template ngFor let-link [ngForOf]="category.items">

        <ng-container *ngTemplateOutlet="menuLink; context: { $implicit: link }"></ng-container>

      </ng-template>

    </ul>
  </li>
</ng-template>

<!-- Menu Link -->
<ng-template #menuLink let-link>
  <ng-container *ngTemplateOutlet="isExternalLink(link) ? menuLinkExternal : menuLinkLocal; context: { $implicit: link }">
  </ng-container>
</ng-template>

<!-- Menu Link Local -->
<ng-template #menuLinkLocal let-link>
  <li nz-menu-item *ngIf="!link.hasOwnProperty('restrictTo') || auth.authorizeResource(link.restrictTo)"
      routerLink="{{link.target}}" nzMatchRouter="true"
      class="menu-icon-item">

    <span *ngIf="link.hasOwnProperty('icon')" class="icon {{link.icon}}"></span>
    <span class="text">{{link.title | translate}}</span>

  </li>
</ng-template>

<!-- Menu Link External -->
<ng-template #menuLinkExternal let-link>
    <li nz-menu-item *ngIf="!link.hasOwnProperty('restrictTo') || auth.authorizeResource(link.restrictTo)"
        nzMatchRouter="true"
        class="menu-icon-item">

      <a [href]="link.target" target="_blank">
        <span *ngIf="link.hasOwnProperty('icon')" class="icon {{link.icon}}"></span>
        <span class="text">{{link.title | translate}}</span>
        &nbsp;
        <span class="icon fe fe-external-link"></span>
      </a>

    </li>
</ng-template>

    </ul>
</nav>
